import { Grid } from "@mui/material";
import Number from "./atoms/Number";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BookIcon from "@mui/icons-material/Book";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import QuizIcon from "@mui/icons-material/Quiz";
import TaskIcon from "@mui/icons-material/Task";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PaidIcon from "@mui/icons-material/Paid";
import StorageIcon from "@mui/icons-material/Storage";
// import QrCode2Icon from "@mui/icons-material/QrCode2";
import Chart from "../Chart/Chart";
import { useSelector } from "react-redux";
import { features } from "@config/features";

export default function Numbers() {
  let lang = useSelector((state) => state?.global?.lang);
  const { data: systemData } = useCustomQuery({
    url: "system/numbers",
    key: ["systemNumbers", lang],
    select: (data) => data?.data?.data,
  });

  return (
    <Grid
      container
      gap={3}
      sx={{
        width: "100%",
        paddingY: "50px",
      }}
    >
      <Grid xs={11} md={2.8}>
        <Number
          name={lang == "ar" ? "كورس" : "Course"}
          value={systemData?.numberOfCourses}
          hex={COLORS.PRIMARY.MAIN}
          icon={MenuBookIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={lang == "ar" ? "طالب" : "Student"}
          value={systemData?.numberOfStudents}
          hex={COLORS.SECONDARY.MAIN}
          icon={SchoolIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={lang == "ar" ? "اشتراك" : "subscription"}
          value={systemData?.numberOfStudentsInCourses}
          hex={COLORS.TERTIARY.MAIN}
          icon={HowToRegIcon}
        />
      </Grid>

      {/* ---------------------------------------------------------- */}

      <Grid xs={11} md={2.8}>
        <Number
          name={lang == "ar" ? "كتب" : "Book"}
          value={systemData?.NumberofBooks}
          hex={COLORS.ORANGE}
          icon={BookIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={lang === "ar" ? "ملفات مرفقة" : "Drives"}
          value={systemData?.NumberofDrives}
          hex={COLORS.GREEN}
          icon={AttachFileIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "امتحان" : "Exam")}
          value={systemData?.NumberofExams}
          hex={COLORS.LIGHT_BLUE}
          icon={QuizIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "واجب" : "Homework")}
          value={systemData?.NumberofHomworks}
          hex={COLORS.SEA_GREEN}
          icon={TaskIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "فيديو" : "Video")}
          value={systemData?.NumberofVideos}
          hex={COLORS.VIOLET}
          icon={OndemandVideoIcon}
        />
      </Grid>
      {/* ---------------------------------------------------------- */}
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "درس مجاني" : "Free Lesson")}
          value={systemData?.numberOfFreeLessons}
          hex={COLORS.GOLD}
          icon={PlaylistAddCheckIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "اجمالي المدفوعات" : "Total paid")}
          value={systemData?.TotalPaid}
          hex={COLORS.GRAY}
          icon={PaidIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "شحن الطلاب" : "Total Charging")}
          value={systemData?.TotalCharging}
          hex={COLORS.VIOLET}
          icon={PaidIcon}
        />
      </Grid>
          {/* If bunny feature true */}

      {features.bunny &&
      <>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "سعة التخزين" : "storage")}
          value={systemData?.storage + `GB`}
          hex={COLORS.SEA_GREEN}
          icon={StorageIcon}
        />
      </Grid>
      <Grid xs={11} md={2.8}>
        <Number
          name={(lang === "ar" ? "الإستهلاك" : "band width")}
          value={systemData? systemData.bandwidth + `GB`:(lang === "ar" ? "غير معرف" : "not found") }
          hex={COLORS.GRAY}
          icon={StorageIcon}
        />
      </Grid>
      </>
}
      <Grid xs={11} md={5.8}>
        <Chart />
      </Grid>
      {/* <Grid xs={11} md={2.8}>
        <Number
          name={"الاكواد"}
          value={systemData?.TotalCreatedCodes}
          hex={COLORS.PURPLE}
          icon={QrCode2Icon}
        />
      </Grid> */}
    </Grid>
  );
}
